import axios from 'axios';

const Modal = require('bootstrap/js/dist/modal');

$(function () {
  let openEventModal = function () {
    let query = new URLSearchParams(window.location.search);

    if (query.get('open') === 'modal') {
      let event = query.get('event');

      let $btn = $(`[data-toggle="event-detail"][data-event="${ event }"]`);

      $btn.trigger('click');

      let $modal = $($btn.data('target'));

      $modal.on('hidden.bs.modal', async function () {
        let href = window.location.href.replace(window.location.search, '');

        await window.history.replaceState({}, '', href);

        $(this).unbind('hidden.bs.modal');
      });
    }
  };

  $(document).on('click', '[data-toggle="speaker-detail"]', async function (e) {
    e.preventDefault();

    const $btn = $(this);
    const [$modal] = $($btn.data('target'));

    if (!!$modal) {
      const modal = Modal.getOrCreateInstance($modal);

      modal.show();

      let $body = $('.modal-body', $modal);

      $body.html('<div class="loader"></div>');

      let { data } = (await axios.get(`/wp-json/speaker/${ $btn.data('speaker') }`));

      $body.html(data);
    }
  });

  openEventModal();
});
