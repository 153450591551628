try {
  window.$ = window.jQuery;

  // window.bootstrap = require('bootstrap/js/src/modal');

  document.addEventListener('DOMContentLoaded', function (event) {
    require('./parts/SpeakersModal.js');
  });
} catch (e) {
  console.error(e);
}
